$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		warehouseGallery();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			$('header.site-header').addClass('menu-active');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			$('header.site-header').removeClass('menu-active');
			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});

	});
});

function warehouseGallery(){
	$(document).on('click', '.warehouses .warehouse-item', function(){
		var id = $(this).data('id'),
				key = $(this).data('key');
		$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_gallery',
					id : id,
					key : key
				},
				beforeSend: function(){
					$('#warehouse').css({
						'opacity' : 0.5
					});
				},
				success: function(response) {
					$('#warehouse .carousel-inner').html(response.content);
					setTimeout(function(){
						$('#warehouse').css({
							'opacity' : 1
						});
					}, 300);
				}
			});
	});
}